.react-calendar {
    width: 100%;
    max-width: 100%;
    line-height: 1.125em;
    background-color: rgba(245, 249, 249, 1);
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: .5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    height: 44px;
    background-color: rgba(218, 223, 251, 1);
    border-radius: 7px 7px 0 0;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: rgba(245, 249, 249, 1);
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: .75em;
    background-color: rgba(239, 241, 251, 1);
}

.react-calendar__month-view__weekdays__weekday {
    padding: .5em;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}

.react-calendar__navigation__label {
    text-transform: uppercase;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75em;
    padding: calc(1em) calc(0.6666666666666666em);
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em .5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: .75em .5em;
    background-color: rgba(21, 213, 147, 1);
    color: black;
    border-radius: 0;
}

.react-calendar__tile:disabled {
    background-color: transparent;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: rgba(21, 213, 147, 1);
}

.react-calendar__tile--now {
    color: black;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    color: black;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: #006edc;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.legend {
    display: flex;
    margin-top: 25px;
    width: 100%;
    align-items: center;
}

.legend .dot {
    margin-right: 7px;
    fill: rgba(21, 213, 147, 1);
    box-shadow: none;
    width: 16.00px;
    height: 16.00px;
}
  